import { useState, useMemo } from 'react'
import {
  EuiBasicTable,
  EuiSpacer,
  EuiHorizontalRule,
  EuiText,
  EuiFlexItem,
  EuiButton,
  EuiPageTemplate,
  EuiFieldText,
  EuiPopover,
} from '@elastic/eui'
import { Link } from 'react-router-dom'

import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
// import useDebounce from 'hooks/useDebounce'
import { useDebounce } from 'use-debounce'
import useLocalStorage from 'hooks/useLocalStorage'
import { Talent } from 'helpers/Talent'
import axios from 'axios'
import _ from 'lodash'
import applyFilters from './applyFilters'
import { safelyMillify } from 'utils'
import { addTags } from 'redux/tagsSlice'
import { setTags } from 'redux/influencerSlice'
import 'styles/_PrivateTalentRoster.scss'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import { AiOutlineTags } from 'react-icons/ai'
import { setProfileSidebar } from 'redux/modalsSlice'

//@ts-ignore
import { ReactComponent as MagicWand } from 'assets/magic-wand.svg'

//@ts-ignore
import { ReactComponent as LinkIcon } from 'assets/link.svg'
//@ts-ignore
import { ReactComponent as UserPlusIcon } from 'assets/user-plus-02.svg'

import RenderMarkdown from 'components/RenderMarkdown'
import SocialIcon from 'components/SocialIcon'
import AutoSuggest from 'components/AutoSuggest'
import NewSidebarProfile from 'components/NewSidebarProfile'
import SpecialTag from 'components/SpecialTag'
import PriceTag from 'components/PriceTag'
import config from 'config'
import ClickToCopy from 'components/ClickToCopy'
import { RiLink } from 'react-icons/ri'

const CustomIcon = ({ Icon, size = '22px' }) => {
  return <Icon style={{ width: size, height: size }} />
}

const filterByTitle = (sheet, titleSearchTerm) => {
  const search = titleSearchTerm.toLowerCase()
  const result = sheet?.title && sheet.title.toLowerCase().includes(search)
  return result
}

const orderInfluencers = (influencers, orderBy, sortDirection = 'asc') => {
  if (!orderBy) return influencers

  if (orderBy === 'fullname') {
    return _.orderBy(influencers, ['fullname'], [sortDirection])
  }

  if (orderBy === 'last_name') {
    // try to split the full name and sort by last name. it won't always work
    return _.orderBy(
      influencers,
      [
        (influencer) => {
          const splitName = influencer.fullname.split(' ')
          return splitName[splitName.length - 1]
        },
      ],
      [sortDirection],
    )
  }

  // fallback, return the original list if we don't know how to order
  return influencers
}

const RosterTable = ({ sheetType = 'talent', setSelectedPerson, selectedPerson }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [showPerPageOptions, setShowPerPageOptions] = useState(true)
  const suggestedTags = useSelector((state) => state.tags.tags)

  const [popoverId, setPopoverId] = useState(null)

  const onTableChange = ({ page, sort }) => {
    if (page) {
      const { index: pageIndex, size: pageSize } = page
      setPageIndex(pageIndex)
      setPageSize(pageSize)
    }

    if (sort) {
      const { field, direction } = sort
      setSortField(field)
      setSortDirection(direction)
    }
  }

  const searchParams = new URLSearchParams(window.location.search)
  const dispatch = useDispatch()

  const page = Number(searchParams.get('page')) || 1
  const [nameFilterInput, setNameFilterInput] = useState('')

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [sheetForDeletion, setSheetForDeletion] = useState(null)

  const [sortField, setSortField] = useState('created_at')
  const [sortDirection, setSortDirection] = useState('asc')

  const actions = useMemo(() => {
    let actions = [
      {
        name: (sheet) => (sheet.id ? 'Delete' : 'Remove'),
        description: ({ title }) => `Delete`,
        icon: 'trash',
        color: 'danger',
        type: 'icon',
        onClick: (sheet) => {
          setSheetForDeletion(sheet.id)
          setIsDeleteModalVisible(true)
        },
        isPrimary: true,
        'data-test-subj': ({ id }) => `action-delete-${id}`,
      },
    ]
    return actions
  }, [])

  const team_influencer_ids = useSelector((state) => state.influencers.team_influencer_ids)

  const influencers = useSelector((state) => {
    return state.influencers.entities
  })

  const teamInfluencers = useMemo(
    () => _.pick(influencers, team_influencer_ids),
    [influencers, team_influencer_ids],
  )

  const socials = useSelector((state) => state.socials.entities)

  const [orderBy, setOrderBy] = useLocalStorage('rosterOrderBy', null)
  const tagged_influencers = useSelector((state) => state.tags.tagged_influencers)
  const [filteringTags, setFilteringTags] = useState([])
  // const [nameFilter, setNameFilter] = useState('')

  const [nameFilter] = useDebounce(nameFilterInput, 150)

  const masterTags = useSelector((state) => state.tags.masterTags)

  const displayedInfluencers = useMemo(() => {
    if (_.isEmpty(influencers)) return []
    let influencersToShow = _.map(teamInfluencers, (influencer) => influencer)

    if (nameFilter.trim() !== '' || filteringTags.length > 0) {
      //@ts-ignore
      influencersToShow = applyFilters({
        influencers: influencersToShow,
        filteringTags,
        nameFilter,
        taggedInfluencers: tagged_influencers,
        sortField,
      })
    } else {
      if (sortField) {
        console.log('order by', sortField)
        influencersToShow = orderInfluencers(influencersToShow, sortField, sortDirection)
      }
      // influencersToShow = influencersToShow.slice((page - 1) * pageLimit, page * pageLimit)
    }
    return influencersToShow
  }, [filteringTags, page, orderBy, teamInfluencers, nameFilter, sortField, sortDirection])

  const tagsColumn = useMemo(() => {
    return {
      name: 'Tags',
      width: '30%',
      render: (influencer, settings = {}) => {
        const tags = influencer?.tags || []

        const performSave = async (tags = []) => {
          await axios.post('/api/user/influencers/tags', {
            influencer_id: influencer.id,
            tags,
          })
          dispatch(
            addTags({
              tags,
              influencer_id: influencer.id,
            }),
          )
        }

        const handleAddTagFromForm = (tag) => {
          if (tag.trim() === '') return

          const newTags = _.uniq([...tags, tag])
          performSave(newTags)

          dispatch(setTags({ tags: newTags, influencer: influencer.id }))
        }

        return (
          <div className="d-flex flex-column">
            <div className="">
              {tags.map((tagName) => (
                <SpecialTag
                  key={`${influencer.id}-${tagName}`}
                  performRemove={() => {
                    const newTags = _.without(tags, tagName)
                    performSave(newTags)
                    dispatch(setTags({ tags: newTags, influencer: influencer.id }))
                  }}
                  // color="hollow"
                  // iconType="cross"
                  // iconSide="right"
                  // onClick={() => removeTag(tagName)}
                  // onClickAriaLabel="Remove tag"
                  backgroundColor={masterTags.includes(tagName) ? '#9ee8e9' : '#e6fcfc'}
                  // style={{
                  //   backgroundColor: masterTags.includes(tagName) ? '#9ee8e9' : '#e6fcfc',
                  // }}
                  name={tagName}
                />
              ))}
              <EuiPopover
                className="selectable"
                button={
                  <SpecialTag
                    // size="s"
                    // variant="filled"
                    textColor={config.colors['gray-800']}
                    backgroundColor={true ? '#0077cc1a' : config.colors['eui-bright-blue']}
                    onClick={() => {
                      if (popoverId === influencer.id) {
                        setPopoverId(null)
                      } else {
                        setPopoverId(influencer.id)
                      }
                    }}
                    name={'Add Tags +'}
                    className={'fw-bold'}
                  >
                    Add Tags
                  </SpecialTag>
                }
                isOpen={popoverId === influencer.id}
                closePopover={() => setPopoverId(null)}
              >
                <div className="my-2">
                  <AutoSuggest
                    handleAddTagFromForm={handleAddTagFromForm}
                    handleRemoveTag={(tag) => {
                      const newTags = _.without(tags, tag)
                      performSave(newTags)
                      dispatch(setTags({ tags: newTags, influencer: influencer.id }))
                    }}
                    currentTags={tags}
                    allChoices={suggestedTags}
                    inputPlaceholder="Add tag"
                    width={'400px'}
                    allowCreation={true}
                  />
                </div>
              </EuiPopover>
            </div>
          </div>
        )
      },
    }
  }, [popoverId, masterTags, suggestedTags])

  const columns = useMemo(() => {
    return [
      {
        name: 'Talent Name',
        field: 'fullname',
        // align: 'center',
        sortable: (influencer) => influencer.fullname,
        truncateText: false,
        width: '15%',
        render: (xxx, influencer) => {
          const talent = new Talent(influencer, socials)
          return (
            <div className="portrait-rounded d-flex flex-column align-middle selectable">
              <div className="m-3 text-center">
                <img
                  data-purpose="influencer-portrait"
                  src={talent.getPhotoUrl()}
                  onClick={() => {
                    if (selectedPerson === influencer.id) {
                      setSelectedPerson(null)
                    } else {
                      setSelectedPerson(influencer.id)
                    }
                  }}
                  style={{
                    width: '95px', // or any size you want
                    // height: '75px', // or any size you want
                    borderRadius: '5px', // adjust for desired roundness
                  }}
                />
              </div>
              <div className="text-center">
                <Link to={`/influencers/profile/${influencer.id}`}>
                  <span
                    style={{
                      fontSize: '14px',
                    }}
                    className="fw-bold text-black"
                  >
                    {talent.influencer.fullname}
                  </span>
                </Link>
              </div>
            </div>
          )
        },
      },
      {
        name: 'Description',
        width: '30%',
        render: (influencer) => {
          const description = _.isEmpty(influencer.custom_description)
            ? _.get(influencer, 'description', '')
            : _.get(influencer, 'custom_description', '')

          return (
            <EuiText size="s">
              <RenderMarkdown markdownText={description} textColor={'black'} />
            </EuiText>
          )
        },
      },
      {
        name: 'Total Reach',
        align: 'center',
        width: '10%',
        render: (influencer, settings = {}) => {
          const talent = new Talent(influencer, socials)
          const talentSocials = talent.getSocials()

          const influencerSocials = talentSocials.allSocials

          const totalReach = influencerSocials.reduce((acc, social) => {
            if (!social.followers) return acc

            return acc + social.followers
          }, 0)

          return (
            <div className="d-flex flex-column team-roster-social-list">
              <div className="fs-5 fw-bold">{totalReach ? safelyMillify(totalReach) : ''}</div>
            </div>
          )
        },
      },
      {
        name: 'Socials',
        render: (influencer, settings = {}) => {
          const talent = new Talent(influencer, socials)
          const talentSocials = talent.getSocials()

          const influencerSocials = talentSocials.allSocials

          return (
            <div className="d-flex flex-column team-roster-social-list">
              {influencerSocials.map((social, i) => {
                if (!social) return null
                const name =
                  social.platform === 'youtube'
                    ? social.platform_account_nickname
                    : social.platform_account_name

                return (
                  <div className="" key={`influencer-social-${i}`}>
                    <a style={{ color: 'black' }} target="_blank" href={social.profile_url}>
                      <SocialIcon platform={social.platform} /> {name}{' '}
                      {social.followers ? '|' : ''} {safelyMillify(social.followers)}
                    </a>
                  </div>
                )
              })}
            </div>
          )
        },
      },
      tagsColumn,
    ]
  }, [popoverId, masterTags, suggestedTags, socials, selectedPerson])

  const findInfluencers = (influencers, pageIndex, pageSize) => {
    let pageOfItems
    if (!pageIndex && !pageSize) {
      pageOfItems = influencers
    } else {
      const startIndex = pageIndex * pageSize
      pageOfItems = influencers.slice(
        startIndex,
        Math.min(startIndex + pageSize, influencers.length),
      )
    }

    return {
      pageOfItems,
      totalItemCount: influencers.length,
    }
  }

  const { pageOfItems, totalItemCount } = useMemo(() => {
    return findInfluencers(displayedInfluencers, pageIndex, pageSize)
  }, [displayedInfluencers, pageIndex, pageSize])

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount,
    pageSizeOptions: [25, 50],
    showPerPageOptions,
  }

  const resultsCount =
    pageSize === 0 ? (
      <strong>All</strong>
    ) : (
      <>
        <strong>
          {pageSize * pageIndex + 1}-{Math.min(pageSize * pageIndex + pageSize, totalItemCount)}
        </strong>{' '}
        of {totalItemCount}
      </>
    )
  return (
    <>
      <div className="d-flex justify-content-end">
        <EuiFlexItem grow={false}>
          <EuiFieldText
            placeholder="Filter by name"
            value={nameFilterInput}
            onChange={(e) => {
              setNameFilterInput(e.target.value)
            }}
            append={
              <div className="d-flex justify-content-center align-items-center px-3">
                <FaMagnifyingGlass />
              </div>
            }
          />
        </EuiFlexItem>
        <EuiFlexItem className="ms-3" grow={false}>
          <div
            style={{
              width: '280px',
            }}
          >
            <AutoSuggest
              handleAddTagFromForm={(tag) => {
                const newTags = [...filteringTags, tag]

                setFilteringTags(newTags)
              }}
              handleRemoveTag={(tag) => {
                const newTags = _.without(filteringTags, tag)
                setFilteringTags(newTags)
              }}
              currentTags={filteringTags}
              allChoices={suggestedTags}
              inputPlaceholder="Filter by tag"
            />
          </div>
        </EuiFlexItem>
      </div>

      <EuiSpacer size="xl" />
      <EuiText size="xs">
        Showing {resultsCount} <strong>influencers</strong>
      </EuiText>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 2 }} />
      <EuiBasicTable
        sorting={{
          sort: {
            field: sortField,
            direction: sortDirection,
          },
        }}
        tableCaption="Talent Pitch Pro Roster"
        items={pageOfItems}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
      />
    </>
  )
}

export default () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const selectedPerson = useSelector((state) => state.modals.profileSidebar.influencer_id)
  const setSelectedPerson = (id) => {
    dispatch(setProfileSidebar({ influencer_id: id }))
  }

  return (
    <>
      <EuiPageTemplate>
        <div className="container-fluid bg-light py-4 page-header">
          <div className="row align-items-center">
            <div className="col-12 col-md-4">
              <div className="ps-3">
                <h1 className="mb-1 fw-bold page-title">Talent Roster</h1>
                <p className="ms-1 text-muted">View and manage your roster</p>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex flex-column flex-md-row justify-content-end">
                <EuiButton className="me-4" color="text">
                  <ClickToCopy
                    iconId={'talent-roster-copy-icon'}
                    textToCopy={`${window.location.origin}/public/talent-roster/${user.selectedTeamId}`}
                    IconComponent={RiLink}
                    size="18px"
                  />
                  <Link
                    data-purpose="public-roster-link"
                    to={`/public/talent-roster/${user.selectedTeamId}`}
                  >
                    <span className="text-link-blue">Public Link for Sharing</span>
                  </Link>
                </EuiButton>
                <EuiButton className="me-4" color="text">
                  <CustomIcon Icon={UserPlusIcon} size={'20px'} />
                  <Link to="/add-talent">
                    <span className="text-link-blue">Add Talent</span>
                  </Link>
                </EuiButton>

                <EuiButton className="me-4" color="text">
                  <Link to="/influencers/tags">
                    <AiOutlineTags size={'20px'} className="me-2" color="black" />
                    <span className="text-link-blue">View All Tags</span>
                  </Link>
                </EuiButton>

                <button style={{ maxHeight: '60px' }} className="btn btn-bright-blue py-2 me-4">
                  <Link
                    to="/tagging-assistant"
                    style={{
                      color: 'white',
                    }}
                  >
                    <CustomIcon Icon={MagicWand} size={'20px'} />
                    Tagging Assistant
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="d-flex w-100 mt-3">
            <div className={`table-container ${selectedPerson ? '' : ''}`}>
              <RosterTable selectedPerson={selectedPerson} setSelectedPerson={setSelectedPerson} />
            </div>
            <div className={`details-container ${selectedPerson ? 'open' : ''}`}>
              {selectedPerson && (
                <NewSidebarProfile
                  influencer_id={selectedPerson}
                  setSelectedPerson={setSelectedPerson}
                />
              )}
            </div>
          </div>
        </div>
      </EuiPageTemplate>
    </>
  )
}
